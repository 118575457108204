import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import Image from "../components/image"
import { Link, graphql } from "gatsby"

var uniqid = require('uniqid');

const NotFoundPage = ({data}) => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="relative h-screen flex flex-col">
        {data.notFound.edges.map(({ node }) => (
            <Image key={uniqid()} alt={node.frontmatter.mainImage}  filename={node.frontmatter.mainImage} />
        ))}
        <Header />
        <div className="z-10 h-full flex flex-col mt-56 lg:mt-32 items-center">
            <h1 className="mb-2 p-1 uppercase bg-white" style={{width: `fit-content`, color: `#1A1A19`}}>NOT FOUND</h1>
            <p className="text-center">You just hit a route that doesn&#39;t exist... the sadness.</p>
            <Link to="/" className="p-2">Back to website &rarr;</Link>
        </div>
    </div>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
query notFoundQuery {
    notFound: allMarkdownRemark(
    filter: {frontmatter: {content: {eq: "notFound"}}}
  ) {
    edges {
      node {
        frontmatter {
            mainImage
        }
      }
    }
  }
}
`